import React from 'react';

const About = () => {

  return (
    <div>
      <h1>About page</h1>
      <p>This is the About page of our app.</p>
    </div>
  );
};

export default About;
